@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
}

.sp.sp-slices {
  border-radius: 50%;
  border-top: 16px rgba(13, 148, 136, 0.75) solid;
  border-left: 16px rgba(13, 148, 136, 0.25) solid;
  border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
  border-right: 16px rgba(13, 148, 136, 0.25) solid;
  -webkit-animation: spSlices 1s infinite linear;
  animation: spSlices 1s infinite linear;
}

@-webkit-keyframes spSlices {
  0% {
    border-top: 16px rgba(13, 148, 136, 0.75) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
  25% {
    border-top: 16px rgba(13, 148, 136, 0.25) solid;
    border-right: 16px rgba(13, 148, 136, 0.75) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
  50% {
    border-top: 16px rgba(13, 148, 136, 0.25) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.75) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
  75% {
    border-top: 16px rgba(13, 148, 136, 0.25) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.75) solid;
  }
  100% {
    border-top: 16px rgba(13, 148, 136, 0.75) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
}

@keyframes spSlices {
  0% {
    border-top: 16px rgba(13, 148, 136, 0.75) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
  25% {
    border-top: 16px rgba(13, 148, 136, 0.25) solid;
    border-right: 16px rgba(13, 148, 136, 0.75) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
  50% {
    border-top: 16px rgba(13, 148, 136, 0.25) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.75) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
  75% {
    border-top: 16px rgba(13, 148, 136, 0.25) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.75) solid;
  }
  100% {
    border-top: 16px rgba(13, 148, 136, 0.75) solid;
    border-right: 16px rgba(13, 148, 136, 0.25) solid;
    border-bottom: 16px rgba(13, 148, 136, 0.25) solid;
    border-left: 16px rgba(13, 148, 136, 0.25) solid;
  }
}

@-moz-keyframes throbber-loader {
  0% {
    @apply bg-bluegray-100;
  }
  10% {
    @apply bg-teal-400;
  }
  40% {
    @apply bg-bluegray-100;
  }
}
@-webkit-keyframes throbber-loader {
  0% {
    @apply bg-bluegray-100;
  }
  10% {
    @apply bg-teal-400;
  }
  40% {
    @apply bg-bluegray-100;
  }
}
@keyframes throbber-loader {
  0% {
    @apply bg-bluegray-100;
  }
  10% {
    @apply bg-teal-400;
  }
  40% {
    @apply bg-bluegray-100;
  }
}
/* :not(:required) hides these rules from IE9 and below */
.throbber-loader:not(:required) {
  -moz-animation: throbber-loader 2000ms 300ms infinite ease-out;
  -webkit-animation: throbber-loader 2000ms 300ms infinite ease-out;
  animation: throbber-loader 2000ms 300ms infinite ease-out;
  @apply bg-bluegray-100;
  display: inline-block;
  position: relative;
  text-indent: -9999px;
  width: 0.9em;
  height: 1.5em;
}
.throbber-loader:not(:required):before,
.throbber-loader:not(:required):after {
  @apply bg-bluegray-100;
  content: "\x200B";
  display: inline-block;
  width: 0.9em;
  height: 1.5em;
  position: absolute;
  top: 0;
}
.throbber-loader:not(:required):before {
  -moz-animation: throbber-loader 2000ms 150ms infinite ease-out;
  -webkit-animation: throbber-loader 2000ms 150ms infinite ease-out;
  animation: throbber-loader 2000ms 150ms infinite ease-out;
  left: -1.6em;
}
.throbber-loader:not(:required):after {
  -moz-animation: throbber-loader 2000ms 450ms infinite ease-out;
  -webkit-animation: throbber-loader 2000ms 450ms infinite ease-out;
  animation: throbber-loader 2000ms 450ms infinite ease-out;
  right: -1.6em;
}

.header-item-input {
  @apply border-0 border-b border-gray-300 focus:border-teal-600 focus:outline-none focus:ring-0;
}
